import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

// Fonts
import "fontsource-montserrat/400.css";
import "fontsource-montserrat/500.css";
import "fontsource-montserrat/600.css";
import "fontsource-montserrat/700.css";

// SVG
import Logo from "../images/sparkrise-logo-vbalance.svg";

// Components
import Navbar from "./navbar";

// Cookie Consent - Tutorial https://www.improvebadcode.com/gatsby-gdpr-cookie-consent/
// https://www.gatsbyjs.com/plugins/gatsby-plugin-gdpr-cookies/#initialize-and-track
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans bg-light-gray">
      <header aria-label="Main Header">
        <Navbar />

        <CookieConsent
          location="top"
          buttonText="Click to Accept"
          cookieName="sparkrise-google-analytics-consent"
          onAccept={() => {
            Cookies.set("sparkrise-facebook-pixel-consent", "true", { path: '/', expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), sameSite: 'lax' });
            initializeAndTrack(useLocation);
          }}
          style={{ justifyContent: "center" }}
          contentStyle={{ flex: "0 0 100%", textAlign: "center" }}
          buttonStyle={{
            marginTop: "0px"
          }}
        >
          This site uses cookies ...{" "}
          <a href="/privacypolicy" target="_blank" rel="noopener noreferrer" className="underline lg:mr-2">
            Privacy Policy
          </a>
        </CookieConsent>

        <script
          dangerouslySetInnerHTML={{
            __html: `
              if (navigator && navigator.serviceWorker && navigator.serviceWorker.getRegistrations) {
                  navigator.serviceWorker.getRegistrations().then((registrations) => {
                    for (let registration of registrations) {
                      registration.unregister()
                    }
                })
              }
            `
          }} />
      </header>

      <main>{children}</main>

      <footer
        className="container flex flex-col justify-center items-center lg:flex-row my-7"
        aria-label="main footer"
        role="contentinfo"
      >
        <Link to="/" className="mr-0 lg:mr-4">
          <Logo style={{ height: "68px", width: "218px" }} />
        </Link>

        <p className="text-center flex flex-col md:flex-row">
          <table className="center">
            <tr>
              Copyright © {new Date().getFullYear()} SparkRaiser, Inc. All Rights Reserved.
            </tr>
            <tr>
              <Link to="/disclosurestatements" className="underline lg:mr-2">
                Disclosures
              </Link>{" "}
              <span className="md:inline px-2"> | </span>
              <Link to="/termsofservice" className="underline lg:mr-2">
                Terms
              </Link>{" "}
              <span className="md:inline px-2"> | </span>
              <Link to="/privacypolicy" className="underline lg:mr-2">
                Privacy
              </Link>
            </tr>
          </table>
        </p>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
