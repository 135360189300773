import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function DisclosuresPage() {
  return (
    <Layout>
      <SEO keywords={[`sparkrise`, `disclosures`]} title="Disclosure Statements" />

      <article className="mt-12">
        <heading className="container">
          <h2 className="c-h3 text-center text-black px-4">
            Disclosure Statements
          </h2>
        </heading>
        <div className="lg:w-8/12 mx-auto px-4 prose max-w-none">
          <ul>
            <li>SparkRise is a technology platform that serves a community.</li>
            <li>The SparkRise community is made up of Causes, Sponsors, Influencers and Fans.</li>
            <li>SparkRise is a for-profit corporation that contracts with Sponsors to launch Campaigns.</li>
            <li>Causes benefit from Campaigns that run on SparkRise.</li>
            <li>Sponsors contribute funds to Causes when they engage in Campaigns.</li>
            <li>Influencers reach out to their networks to bring awareness to Campaigns and the associated Causes and Sponsors.</li>
            <li>Fans engage with Campaigns through watching videos, downloading coupons, sending preset messages, taking surveys or other steps as requested.</li>
            <li>Sponsors choose Campaigns and Causes that align with their brands.</li>
            <li>Sponsors choose SparkRise as an alternative to traditional advertising that can run either as a substitute or complementary communications channel.</li>
            <li>SparkRise tracks engagements metrics during Campaigns and reports these to Sponsors.</li>
            <li>SparkRise collects fees from Sponsors pursuant to contracts.</li>
            <li>The fees are significantly lower than those typically charged by professional solicitors raising funds for charities and philanthropies.</li>
            <li>SparkRise does not receive fees from Causes that benefit from Campaigns.</li>
            <li>SparkRise does not solicit funds for Causes.</li>
            <li>Fans who wish to contribute funds to Causes should do so on their own and cannot do so on SparkRise.</li>
            <li>SparkRise does not contract with Causes except for contracts generated automatically by Causes when, for example, a user downloads a logo.</li>
            <li>SparkRise provides tools to Sponsors as part of its services.</li>
            <li>The tools provided by SparkRise to Sponsors includes a template for an agreement between a Sponsor and a Cause to lay out the actions that Fans will be asked to engage in during a Campaign as well as the steps that the Cause might undertake to enhance the reach of the Campaign.</li>
            <li>SparkRise is not a party to agreements reached between Sponsors and Causes.</li>
          </ul>
        </div>
      </article>
    </Layout >
  );
}

export default DisclosuresPage;
