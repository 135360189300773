/* eslint react/prop-types: 0 */
import React from "react";
import { Link } from "gatsby";

import {
  FiFacebook,
  FiTwitter,
  // FiInstagram
} from "react-icons/fi";

import Logo from "../images/sparkrise-logo-vbalance.svg";

const Navbar = () => {
  return (
    <nav
      id="main-navigation"
      className="relative flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow"
      arial-label="main navigation"
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-center sm:justify-between">
        <h1 className="w-auto relative flex justify-between">
          <Link
            className="inline-block mr-4"
            to="/"
            aria-label="SparkRise Home"
          >
            <Logo style={{ height: "68px", width: "160px" }} />
          </Link>
        </h1>
        <div className="hidden sm:flex flex-grow items-center">
          <ul className="flex flex-row list-none ml-auto text-purple">
            <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center hover:opacity-75"
                href="https://www.facebook.com/SparkRise"
                aria-label="SparkRise on Facebook"
                target="_blank"
                rel="noreferrer"
              >
                <FiFacebook size="20px" />
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center hover:opacity-75"
                href="#instagram"
                aria-label="SparkRise on Instagram"
              >
                <FiInstagram size="20px" />
              </a>
            </li> */}
            <li className="nav-item">
              <a
                className="px-3 py-2 flex items-center hover:opacity-75"
                href="https://twitter.com/WeAreSparkRise"
                aria-label="SparkRise on Twitter"
                target="_blank"
                rel="noreferrer"
              >
                <FiTwitter size="20px" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
